import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/cita/canadian-independent-telecommunications-association-2025-event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/cita/canadian-independent-telecommunications-association-2025-event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/cita/canadian-independent-telecommunications-association-2025-event-og.jpg';

const CITA2025 = () => {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Canadian Independent Telecommunications Association - CITA 2025 - Witbe' isEvents>
            <SEO
                title='Canadian Independent Telecommunications Association - CITA 2025 - Witbe'
                ogDescription='Meet us at CITA on March 31st! Visit Booth 511 to discover Witbe’s Virtual NOC technology and book your live demo today.'
                description='Meet us at CITA on March 31st! Visit Booth 511 to discover Witbe’s Virtual NOC technology and book your live demo today.'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={21} banner={mobileBanner} /> : <DesktopEventPage id={21} banner={banner} />}
        </Layout>
    );
};

export default CITA2025;
